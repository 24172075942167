<template>
  <div class="about">
    <h1>This is an about page</h1>
    {{frames}}
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Editor",
  components: {},
  // data: function () {
  //   return {
  //     activeTab: "image",
  //     fileDragOver: false,
  //     editorBtns: [
  //       { type: "image", iconName: "insert_photo" },
  //       { type: "text", iconName: "text_fields" },
  //       { type: "video", iconName: "movie" },
  //       { type: "audio", iconName: "audiotrack" },
  //     ],
  //   };
  // },
  computed: {
    ...mapState(["frames", "currentFrame"]),
  },
};
</script>
<style lang="scss">
.about {
  color: #000;
}

</style>